import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import RulesAlertM from 'components/RulesAlertM';
import axios from 'axios';

class FormBannerM extends Component {
  constructor(props) {
    super(props);

    this.state = {
      school: '',
      userName: '',
      checkRules: false,
      showModal: false,
      phone: '',
      phoneCode: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getPhoneCode = this.getPhoneCode.bind(this);
  }

  getPhoneCode() {
    if (/^1[3456789]\d{9}$/.test(this.state.phone)) {
      axios
        .post(
          `${process.env.API_DOMAIN}/webapi/sendPotentialUserVerifyCode`,
          { accountNo: this.state.phone },
          {
            headers: {
              Authorization:
                'Basic YTY3YmQ2MzctNzAxMi00OGU5LTgwNDMtODQwNWU2YmRmMTRkOjhkZTgwMWRmLWZkYzUtNGM0Mi05OGQ1LWNhYzE2ZjVkZTNkNg==',
            },
          }
        )
        .then((res) => {
          if (res.data.status === 0) {
            alert('验证码已发送！');
          } else {
            alert('验证码发送失败，请重试！');
          }
        });
    } else {
      alert('手机号格式不正确！');
    }
  }

  changeSchool(e) {
    e.preventDefault();
    this.setState({
      school: e.currentTarget.value,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (
      !this.state.school ||
      !this.state.userName ||
      !/^1[3456789]\d{9}$/.test(this.state.phone) ||
      !/^\d{6}$/.test(this.state.phoneCode)
    ) {
      alert('表单填写有误！');
      return;
    }
    if (!this.state.checkRules) {
      alert('请仔细阅读并勾选用户协议！');
      return;
    }

    axios
      .post(
        `${process.env.API_DOMAIN}/webapi/addPotentialUser`,
        {
          schoolId: this.state.school * 1,
          realName: this.state.userName,
          mobilePhone: this.state.phone,
          verifyCode: this.state.phoneCode,
          instrumentInterest: [this.props.instrument === 'piano' ? 6 : 5],
          customerTypes: [this.props.customer === 'child' ? 1 : 2],
          source: this.props.source || '8',
        },
        {
          headers: {
            Authorization:
              'Basic YTY3YmQ2MzctNzAxMi00OGU5LTgwNDMtODQwNWU2YmRmMTRkOjhkZTgwMWRmLWZkYzUtNGM0Mi05OGQ1LWNhYzE2ZjVkZTNkNg==',
          },
        }
      )
      .then((res) => {
        if (res.data.status === 0) {
          this.props.onSuccess?.();
        } else {
          alert(`预约失败！原因：${res.data.msg}`);
        }
      });
  }

  render() {
    const { landingImage, discountTitle, customer } = this.props;

    return (
      <Fragment>
        <RulesAlertM
          showModal={this.state.showModal}
          closeModal={() => {
            this.setState({
              showModal: false,
            });
          }}
        />
        <div
          className="form-box"
          style={{ width: '100%', height: '603px' }}
          id="form-box"
        >
          {landingImage ? (
            <div
              className="form-box-banner"
              style={{
                width: '100%',
                height: '250px',
                fontSize: '0px',
              }}
            >
              <img
                style={{ width: '100%', height: '100%' }}
                src={landingImage}
                alt="landing-image"
              />
            </div>
          ) : null}

          {
            <div
              className="form-box-content"
              style={{
                width: '100%',
                height: '353px',
                padding: '0px 28px 20px',
                boxSizing: 'border-box',
                background: 'rgba(248,248,248,1)',
              }}
            >
              <div
                className="form-box-title"
                style={{
                  width: '100%',
                  height: '80px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    fontSize: '20px',
                    fontFamily: 'PingFangSC-Light,PingFang SC',
                    fontWeight: 300,
                    color: 'rgba(34,34,34,1)',
                    lineHeight: '28px',
                  }}
                >
                  免费预约价值
                </div>
                <div
                  style={{
                    fontSize: '20px',
                    fontFamily: 'PingFangSC-Light,PingFang SC',
                    fontWeight: 300,
                    color: 'rgba(34,34,34,1)',
                    lineHeight: '28px',
                  }}
                >
                  {discountTitle}
                </div>
              </div>
              <div className="form-box-content">
                <form onSubmit={this.handleSubmit}>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <select
                      name="school"
                      style={{
                        width: '320px',
                        height: '40px',
                        background: 'rgba(255,255,255,1)',
                        borderRadius: '4px',
                        border: '1px solid rgba(213,213,213,1)',
                        marginBottom: '5px',
                        padding: '0 8px',
                        boxSizing: 'border-box',
                        color: '#666',
                      }}
                      value={this.state.school}
                      onChange={this.changeSchool.bind(this)}
                    >
                      <option value="" hidden>
                        选择校区
                      </option>
                      <option value={6}>西溪湿地总校</option>
                      <option value={5}>中大银泰校区</option>
                      <option value={7}>大悦城校区</option>
                      <option value={8}>黄龙旗舰校区</option>
                      <option value={11}>西溪印象城校区</option>
                      <option value={15}>
                        绿城指教社区音乐中心（杨柳郡校区）
                      </option>
                    </select>
                  </div>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <input
                      style={{
                        width: '320px',
                        height: '40px',
                        background: 'rgba(255,255,255,1)',
                        borderRadius: '4px',
                        border: '1px solid rgba(213,213,213,1)',
                        marginBottom: '5px',
                        padding: '0 10px',
                        boxSizing: 'border-box',
                      }}
                      type="text"
                      name="userName"
                      placeholder={
                        customer === 'child'
                          ? '请输入孩子的姓名'
                          : '请输入您的姓名'
                      }
                      maxLength="20"
                      value={this.state.userName}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <input
                      style={{
                        width: '320px',
                        height: '40px',
                        background: 'rgba(255,255,255,1)',
                        borderRadius: '4px',
                        border: '1px solid rgba(213,213,213,1)',
                        marginBottom: '5px',
                        padding: '0 10px',
                        boxSizing: 'border-box',
                      }}
                      type="text"
                      name="phone"
                      placeholder="请输入您的手机号"
                      maxLength="11"
                      value={this.state.phone}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '320px',
                        position: 'relative',
                        height: '100%',
                      }}
                    >
                      <div
                        style={{
                          width: '110px',
                          height: '40px',
                          background: 'rgba(227,0,78,1)',
                          borderRadius: '0px 4px 4px 0px',
                          position: 'absolute',
                          right: 0,
                          top: 0,
                          color: '#fff',
                          fontSize: 14,
                          lineHeight: '40px',
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.getPhoneCode();
                        }}
                      >
                        获取验证码
                      </div>
                      <input
                        style={{
                          width: '320px',
                          height: '40px',
                          background: 'rgba(255,255,255,1)',
                          borderRadius: '4px',
                          border: '1px solid rgba(213,213,213,1)',
                          marginBottom: '5px',
                          padding: '0 10px',
                          boxSizing: 'border-box',
                        }}
                        type="text"
                        maxLength="6"
                        name="phoneCode"
                        placeholder="请输入手机验证码"
                        value={this.state.phoneCode}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      margin: '10px 0px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <input
                      type="checkbox"
                      style={{ marginRight: '5px' }}
                      checked={this.state.checkRules}
                      onChange={() => {
                        this.setState({
                          checkRules: !this.state.checkRules,
                        });
                      }}
                    />
                    我已知晓并接受
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          showModal: true,
                        });
                      }}
                    >
                      《个人信息收集使用规则》
                    </span>
                  </div>
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <button
                      type="submit"
                      style={{
                        width: '320px',
                        height: '42px',
                        background:
                          'linear-gradient(141deg,rgba(64,67,65,1) 0%,rgba(47,50,49,1) 39%,rgba(42,46,44,1) 100%)',
                        borderRadius: '4px',
                        fontSize: '16px',
                        fontFamily: 'PingFangSC-Light,PingFang SC',
                        fontweight: 300,
                        color: 'rgba(255,255,255,1)',
                        lineHeight: '42px',
                        textAlign: 'center',
                        cursor: 'pointer',
                      }}
                    >
                      免费预约
                    </button>
                  </div>
                </form>
              </div>
            </div>
          }
        </div>
      </Fragment>
    );
  }
}

FormBannerM.propTypes = {
  landingImage: PropTypes.string,
  discountTitle: PropTypes.string,
  instrument: PropTypes.oneOf(['piano', 'voice']).isRequired,
  customer: PropTypes.oneOf(['adult', 'child']).isRequired,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func,
};

FormBannerM.defaultProps = {
  onSuccess: function () {},
};

export default FormBannerM;
