import axios from 'axios';

let instance = null;

const wexin = (cb) => {
  if (instance) {
    cb && cb(instance);
  } else if (typeof window === 'undefined') {
    return;
  }

  window.wx.ready(() => {
    instance = window.wx;
    cb && cb(instance);
  });

  window.wx.error((err) => {
    console.log('weixinsdk:', err);
  });

  // 注册
  axios
    .get('https://www.finger66.com/wechat/jsticket', {
      params: { url: `${window.location.origin}${window.location.pathname}` },
    })
    .then(({ data }) => {
      window.wx.config({
        ...(data.res || {}),
      });
    })
    .catch((err) => {
      console.log('weixinsdk:', err);
    });
};

export default wexin;
